import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../Config/firebaseConfig';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { Container, TextField, Button, Typography, Avatar, Switch, FormControlLabel, Paper, Box } from '@mui/material';

const Profile = () => {
  const [user] = useAuthState(auth);
  const [profileData, setProfileData] = useState({ name: '', email: '', nickname: '', showPhoto: true });
  const [nickname, setNickname] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const fetchProfileData = async () => {
        try {
          const profileRef = doc(db, 'users', user.uid, 'profile', 'profileInfo');
          const profileSnap = await getDoc(profileRef);
          
          if (profileSnap.exists()) {
            const data = profileSnap.data();
            setProfileData(data);
            setNickname(data.nickname || '');
            setIsPublic(data.public || false);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching profile data:', error);
          setLoading(false);
        }
      };

      fetchProfileData();
    }
  }, [user]);

  const handleSave = async () => {
    if (!nickname) {
      alert('Nickname is required.');
      return;
    }

    try {
      const profileRef = doc(db, 'users', user.uid, 'profile', 'profileInfo');
      const newProfileData = {
        ...profileData,
        nickname,
        public: isPublic,
        photoURL: user.photoURL || '',
      };

      await setDoc(profileRef, newProfileData);
      navigate(`/to/${nickname}`);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  if (!user) {
    return (
      <Container>
        <Typography variant="h6">Você precisa estar logado para acessar o perfil.</Typography>
      </Container>
    );
  }

  if (loading) {
    return <Typography variant="h6">Carregando...</Typography>;
  }

  
  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4, maxWidth: 500 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {profileData.showPhoto && (
            <Avatar alt={user.displayName} src={user.photoURL} sx={{ width: 100, height: 100 }} />
          )}
          <Typography variant="h5" sx={{ marginTop: 2 }}>
            {user.displayName}
          </Typography>
          <Typography variant="subtitle1" color="textDisabled" sx={{ marginTop: 2 }}>
            {user.email}
          </Typography>
          {/* <TextField
            label="Nome"
            value={profileData.name}
            onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
            fullWidth
            margin="normal"
          /> */}
          {/* <TextField
            label="Email"
            value={profileData.email}
            onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nickname"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Switch
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              />
            }
            label="Perfil público"
          />
          <FormControlLabel
            control={
              <Switch
                checked={profileData.showPhoto}
                onChange={(e) => setProfileData({ ...profileData, showPhoto: e.target.checked })}
              />
            }
            label="Mostrar foto"
          /> */}
          {/* <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>Salvar</Button> */}
        </Box>
      </Paper>
    </Container>
  );
};

export default Profile;
