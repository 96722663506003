import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { db } from '../../Config/firebaseConfig';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';

const DocumentSettingsPage = () => {
    const [newType, setNewType] = useState('');
    const [types, setTypes] = useState([]);

    useEffect(() => {
        loadDocumentTypes();
    }, []);

    const loadDocumentTypes = async () => {
        try {
            const typesCollectionRef = collection(db, 'documentTypes');
            const querySnapshot = await getDocs(typesCollectionRef);
            const loadedTypes = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                docType: doc.data().docType,
            }));
            setTypes(loadedTypes);
        } catch (error) {
            console.error('Error loading document types:', error);
        }
    };

    const handleAddType = async () => {
        if (newType.trim()) {
            try {
                const newTypeData = { docType: newType.trim() };
                const typesCollectionRef = collection(db, 'documentTypes');
                const docRef = await addDoc(typesCollectionRef, newTypeData);

                setTypes([...types, { id: docRef.id, docType: newType }]);
                setNewType('');
            } catch (error) {
                console.error('Error adding new type:', error);
            }
        }
    };

    const handleDeleteType = async (typeId) => {
        try {
            const typeDocRef = doc(db, 'documentTypes', typeId);
            await deleteDoc(typeDocRef);

            const updatedTypes = types.filter((type) => type.id !== typeId);
            setTypes(updatedTypes);
        } catch (error) {
            console.error('Error deleting document type:', error);
        }
    };

    return (
        <Box>
            <Typography variant="h6">Configure Document Types</Typography>
            <Box sx={{ marginTop: 2, display: 'flex', alignItems: 'flex-start' }}>
                <TextField
                    label="New Document Type"
                    value={newType}
                    onChange={(e) => setNewType(e.target.value)}
                    sx={{ marginRight: 2 }}
                />
                <Button variant="contained" color="primary" onClick={handleAddType}>
                    Add Type
                </Button>
            </Box>

            <List sx={{ marginTop: 2 }}>
                {types.map((type) => (
                    <ListItem
                        key={type.id}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteType(type.id)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemText primary={type.docType} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default DocumentSettingsPage;
