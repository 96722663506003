import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db, storage } from '../../Config/firebaseConfig';
import { doc, setDoc, updateDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { Container, Typography, Box, Button, Grid, Card, CardContent, CardActions, IconButton, InputAdornment, InputLabel, FormControl, OutlinedInput, CardMedia, TextField, ImageList, ImageListItem, DialogActions, Alert } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Info from '@mui/icons-material/Info';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Message } from '@mui/icons-material';
import History from '@mui/icons-material/History';
import { Snackbar } from '@mui/material';
import { Dialog } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';

const StoreManagerPage = () => {
    const [user] = useAuthState(auth);
    const [ads, setAds] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [files, setFiles] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAd, setSelectedAd] = useState(null);
    const [isEditing, setIsEditing] = useState(false);  // Para controlar se estamos editando ou criando um novo anúncio



    const handleMenuClick = (event, ad) => {
        setAnchorEl(event.currentTarget);
        setSelectedAd(ad); // Armazena o anúncio selecionado
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedAd(null);
    };

    useEffect(() => {
        if (user) {
            fetchAds();
        }
    }, [user]);

    // Função para redimensionar a imagem
    const resizeImage = async (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e) => {
                img.src = e.target.result;
            };

            reader.readAsDataURL(file);

            img.onload = async () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxWidth = 1080;

                const width = img.width;
                const height = img.height;

                // Calcula a nova altura mantendo a proporção
                const newWidth = Math.min(maxWidth, width);
                const newHeight = (newWidth / width) * height;

                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                canvas.toBlob((blob) => {
                    resolve(new File([blob], file.name, { type: file.type }));
                }, file.type);
            };

            img.onerror = (error) => reject(error);
        });
    };


    const fetchAds = async () => {
        try {
            const adeskUserID = user.uid.slice(0, 16);
            const adsRef = collection(db, `store/${adeskUserID}/ads`);
            const querySnapshot = await getDocs(adsRef);
            const fetchedAds = [];
            querySnapshot.forEach((doc) => {
                fetchedAds.push({ id: doc.id, ...doc.data() });
            });
            setAds(fetchedAds);
        } catch (error) {
            console.error('Error fetching ads:', error);
        }
    };

    useEffect(() => {
        if (user && user.uid) {
            fetchAds();
        }
    }, [user]);

    const handleCreateAd = async () => {
        if (!user || !user.uid) {
            alert('User is not authenticated.');
            return;
        }
    
        if (!title || !description || !price || (!files.length && !imageUrls.length)) {
            alert('Please fill in all fields and upload at least one image.');
            return;
        }
    
        setLoading(true);
    
        try {
            const productId = isEditing ? selectedAd.productId : uuidv4();
            const adeskUserID = user.uid.slice(0, 16);
            const adRef = doc(db, `store/${adeskUserID}/ads/${productId}`);
            
            // Se estamos editando, apenas atualizamos os campos
            if (isEditing) {
                await updateDoc(adRef, {
                    title,
                    description,
                    price,
                    updatedAt: new Date(),
                });
    
                if (files.length) {
                    const uploadPromises = files.map((file) => uploadImage(file, adeskUserID, productId));
                    const uploadedImageUrls = await Promise.all(uploadPromises);
                    const newImageUrls = [...imageUrls, ...uploadedImageUrls];
    
                    await updateDoc(adRef, { imageUrls: newImageUrls });
                    setImageUrls(newImageUrls);
                }
            } else {
                // Se for um novo anúncio, cria tudo do zero
                await setDoc(adRef, {
                    title,
                    description,
                    price,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    creatorId: user.uid,
                    adeskUserID,
                    productId,
                });
    
                const uploadPromises = files.map((file) => uploadImage(file, adeskUserID, productId));
                const uploadedImageUrls = await Promise.all(uploadPromises);
                setImageUrls(uploadedImageUrls);
    
                await updateDoc(adRef, { imageUrls: uploadedImageUrls });
            }
    
            setTitle('');
            setDescription('');
            setPrice('');
            setFiles([]);
            setIsEditing(false);
            setOpenDialog(false);
            setSnackbarMessage(isEditing ? 'Ad updated successfully!' : 'Ad created successfully!');
            setOpenSnackbar(true);
            fetchAds();
        } catch (error) {
            console.error('Error creating/updating ad:', error);
        }
    
        setLoading(false);
    };
    



    const uploadImage = async (file, adeskUserID, productId) => {
        const storageRef = ref(storage, `/store/${adeskUserID}/ads/${productId}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        const snapshot = await uploadTask;
        return getDownloadURL(snapshot.ref);
    };

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const resizedFiles = await Promise.all(files.map(resizeImage));
        setFiles(resizedFiles);
    };

    const handleEditAd = (ad) => {
        if (ad) {  // Verifica se o anúncio existe
            setTitle(ad.title);
            setDescription(ad.description);
            setPrice(ad.price);
            setImageUrls(ad.imageUrls || []);
            setFiles([]);
            setIsEditing(true);
            setOpenDialog(true);
        } else {
            console.error('Ad is null or undefined.');
        }
    };

    // const handleRemoveImage = (index) => {
    //     const updatedFiles = files.filter((_, i) => i !== index);
    //     setFiles(updatedFiles);
    // };

    const handleRemoveImage = (index, isImageUrl) => {
        if (isImageUrl) {
            // Remove a imagem do array imageUrls
            setImageUrls((prev) => prev.filter((_, i) => i !== index));
        } else {
            // Remove a imagem do array files
            setFiles((prev) => prev.filter((_, i) => i !== index));
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleWhatsAppShare = (productId, adeskUserID) => {
        const shareUrl = `${window.location.origin}/store/${adeskUserID}/ads/${productId}`;
        const message = `Confira este anúncio: ${shareUrl}`;
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleDeleteAd = async (productId, adeskUserID, imageUrls) => {
        try {
            // TODO: Implement the code to delete the ad and its associated images
            await deleteDoc(doc(db, `store/${adeskUserID}/ads`, productId));

            const deleteImagePromises = imageUrls.map(async (url) => {
                const imageRef = ref(storage, url);
                await deleteObject(imageRef);
            });

            await Promise.all(deleteImagePromises);

            fetchAds();

            setSnackbarMessage('Ad deleted successfully!');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error deleting ad:', error);
            setSnackbarMessage('Error deleting ad. Please try again.');
            setOpenSnackbar(true);
        }
    };


    return (
        <Container>

            {user && user.uid ?

                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: { lg: 4, xs: 2 } }}>
                        <Typography variant="h5">Advertisements</Typography>
                        {/** adiciona icon de plus */}
                        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)} startIcon={<AddIcon />}>
                            Create advertisement
                        </Button>
                    </Box>

                    <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'flex-start' }}>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}>
                            {ads.map((ad) => (
                                <Card key={ad.id} sx={{ width: 320 }}>
                                    <CardMedia
                                        component="img"
                                        height="240"
                                        image={ad.imageUrls ? ad.imageUrls[0] : 'https://via.placeholder.com/300'}
                                        alt={ad.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">{ad.title}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {ad.description}
                                        </Typography>
                                        <Typography variant="body2" color="textPrimary">
                                            R$ {ad.price}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {new Date(ad.createdAt.seconds * 1000).toLocaleDateString()}
                                        </Typography>
                                    </CardContent>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<WhatsAppIcon />}
                                            onClick={() => handleWhatsAppShare(ad.productId, ad.adeskUserID)}
                                        >
                                            Share
                                        </Button>

                                        <IconButton onClick={(event) => handleMenuClick(event, ad)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => { handleEditAd(selectedAd); handleMenuClose(); }}>
                                                Edit
                                            </MenuItem>
                                            <MenuItem onClick={() => { navigate(`/store/${selectedAd?.adeskUserID}/ads/${selectedAd?.productId}`); handleMenuClose(); }}>
                                                View link
                                            </MenuItem>
                                            <MenuItem onClick={() => { handleDeleteAd(selectedAd?.productId, selectedAd?.adeskUserID, selectedAd?.imageUrls); handleMenuClose(); }}>
                                                Remove
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Card>
                            ))}
                        </Box>
                    </Box></>
                :
                <Container maxWidth="md" sx={{ padding: 4 }}>
                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                        <Typography variant="h1" component="h1" gutterBottom>
                            Venda simplificada
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            Facilite a comunicação e mantenha suas informações de vendas organizadas com a integração que você precisa.
                        </Typography>
                    </Box>

                    <Grid container spacing={4} sx={{ marginTop: 4 }}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'center', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                                <Info sx={{ fontSize: 50, color: 'primary.main' }} />
                                <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
                                    Informações Organizadas
                                </Typography>
                                <Typography variant="body1">
                                    Organize suas informações de maneira lógica e fácil de entender.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'center', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                                <Message sx={{ fontSize: 50, color: 'primary.main' }} />
                                <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
                                    Comunicação Eficiente
                                </Typography>
                                <Typography variant="body1">
                                    Informe sobre vendas e mantenha os clientes atualizados em tempo real.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'center', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                                <History sx={{ fontSize: 50, color: 'primary.main' }} />
                                <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
                                    Histórico de Vendas
                                </Typography>
                                <Typography variant="body1">
                                    Acompanhe facilmente o histórico de produtos vendidos e suas interações.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'center', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                                <CheckCircle sx={{ fontSize: 50, color: 'primary.main' }} />
                                <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
                                    Confirmação Simplificada
                                </Typography>
                                <Typography variant="body1">
                                    Notifique rapidamente sobre o status do produto e se foi vendido ou não.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ textAlign: 'center', mt: 4 }}>
                        <Button disabled variant="contained" color="primary" size="large">
                            Experimente o My Store agora
                        </Button>
                    </Box>
                </Container>
            }

            {/* Dialog para criar novos anúncios */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Create advertisements</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Title"
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        label="Description"
                        fullWidth
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                            label="Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </FormControl>

                    <Button variant="outlined" component="label" sx={{ marginBottom: 2 }}>
                        Upload Images
                        <input type="file" hidden multiple onChange={handleFileChange} />
                    </Button>


<Box>
    {isEditing ? (
        // Se estiver editando, exibe as imagens da lista `imageUrls`
        <ImageList sx={{ width: 500, height: 450 }} cols={3}>
            {imageUrls.map((url, index) => (
                <ImageListItem key={index}>
                    <img
                        src={url}
                        alt={`Image ${index + 1}`}
                        loading="lazy"
                    />
                    <IconButton onClick={() => handleRemoveImage(index, true)}>
                        <DeleteIcon />
                    </IconButton>
                </ImageListItem>
            ))}
        </ImageList>
    ) : (
        // Se não estiver editando, exibe as imagens recém-selecionadas (arquivos)
        <ImageList sx={{ width: 500, height: 450 }} cols={3}>
            {files.map((file, index) => (
                <ImageListItem key={index}>
                    <img
                        src={URL.createObjectURL(file)}
                        alt={`File ${index + 1}`}
                        loading="lazy"
                    />
                    <IconButton onClick={() => handleRemoveImage(index, false)}>
                        <DeleteIcon />
                    </IconButton>
                </ImageListItem>
            ))}
        </ImageList>
    )}
</Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={handleCreateAd} variant="contained" disabled={loading}>
                        {loading ? 'Creating...' : 'Create advertisements'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar para feedback */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default StoreManagerPage;
