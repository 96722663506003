import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Snackbar,
    Alert,
} from '@mui/material';
import TaskList from '../../Components/Task/TaskList';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../Config/AuthContext';
import { db } from '../../Config/firebaseConfig';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import DateDisplay from '../../Components/DateDisplay/DateDisplay'; // Importe seu componente DateDisplay

const ToDoPage = () => {
    const { currentUser } = useAuth();
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [newTask, setNewTask] = useState('');
    const [editTask, setEditTask] = useState(null);
    const [loading, setLoading] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleCreateTask = async () => {
        setLoading(true);
        try {
            const adeskUserID = currentUser.uid.slice(0, 16);
            const tasksRef = collection(db, `tasks/${adeskUserID}/task`);
            await addDoc(tasksRef, {
                userId: currentUser.uid,
                task: newTask,
                status: "Pending",
                createdAt: new Date(), // Mantém o formato do Firestore
            });
            setNewTask('');
            setSnackbarMessage('Tarefa criada com sucesso!');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Erro ao criar tarefa:', error);
            setSnackbarMessage('Erro ao criar tarefa. Tente novamente.');
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
            setOpenDialog(false);
        }
    };

    const handleEditTask = async () => {
        setLoading(true);
        try {
            const adeskUserID = currentUser.uid.slice(0, 16);
            const taskRef = doc(db, `tasks/${adeskUserID}/task`, editTask.id);
            await updateDoc(taskRef, {
                task: editTask.task,
                updatedAt: new Date(), // Mantém o formato do Firestore
            });
            setSnackbarMessage('Tarefa editada com sucesso!');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Erro ao editar tarefa:', error);
            setSnackbarMessage('Erro ao editar tarefa. Tente novamente.');
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
            setOpenEditDialog(false);
            setEditTask(null);
        }
    };

    const handleToggleTaskCompletion = async (taskId, currentStatus) => {
        try {
            const adeskUserID = currentUser.uid.slice(0, 16);
            const taskRef = doc(db, `tasks/${adeskUserID}/task`, taskId);
            await updateDoc(taskRef, { completed: !currentStatus });
        } catch (error) {
            console.error('Erro ao atualizar tarefa:', error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container>
            {currentUser && currentUser.uid ? (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: { lg: 4, xs: 2 },
                        }}
                    >
                        <Typography variant="h5">My tasks</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenDialog(true)}
                            startIcon={<AddIcon />}
                        >
                            Create task
                        </Button>
                    </Box>

                    <Box sx={{ marginTop: 4 }}>
                        <TaskList 
                            onToggleTaskCompletion={handleToggleTaskCompletion} 
                            onEditTask={(task) => {
                                setEditTask(task);
                                setOpenEditDialog(true);
                            }} 
                        />
                    </Box>
                </>
            ) : (
                <Container maxWidth="md" sx={{ padding: 4 }}>
                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                        <Typography variant="h1" component="h1" gutterBottom>
                            Gerenciador de Tarefas
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            Organize suas tarefas de maneira eficiente e prática.
                        </Typography>
                    </Box>
                </Container>
            )}

            {/* Dialog para criar novas tarefas */}
            <Dialog 
                open={openDialog} 
                onClose={() => setOpenDialog(false)} 
                fullWidth 
                maxWidth="md" 
                aria-modal="true"
                scroll='paper'
            >
                <DialogTitle>Criar Tarefa</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Nova Tarefa"
                        fullWidth
                        value={newTask}
                        onChange={(e) => setNewTask(e.target.value)}
                        sx={{ marginBottom: 2 }}
                        minRows={4}
                        multiline
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button onClick={handleCreateTask} variant="contained" disabled={loading}>
                        {loading ? 'Criando...' : 'Criar Tarefa'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog para editar tarefas */}
            <Dialog 
                open={openEditDialog} 
                onClose={() => setOpenEditDialog(false)} 
                fullWidth 
                maxWidth="lg" 
                fullScreen
                aria-modal="true"
            >
                <DialogTitle>Editar Tarefa</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Criado por: {editTask?.userId} <br />
                        Data de Criação: <DateDisplay date={editTask?.createdAt} /> <br />
                        {editTask?.updatedAt && (
                            <>
                                Data de Edição: <DateDisplay date={editTask?.updatedAt} /> <br />
                            </>
                        )}
                    </Typography>
                    <TextField
                        label="Tarefa"
                        fullWidth
                        value={editTask?.task || ''}
                        onChange={(e) => setEditTask({ ...editTask, task: e.target.value })}
                        sx={{ marginBottom: 2 }}
                        minRows={4}
                        multiline
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)}>Cancelar</Button>
                    <Button onClick={handleEditTask} variant="contained" disabled={loading || !editTask?.task}>
                        {loading ? 'Editando...' : 'Salvar Alterações'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar para feedback */}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ToDoPage;
