import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../Config/firebaseConfig';
import { doc, getDoc, setDoc, updateDoc, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { Container, Button, Typography, Box, Snackbar, Alert, Drawer, Switch, FormControlLabel, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
import Masonry from '@mui/lab/Masonry';
import NoteCard from './NoteCard';

const NotesPage = () => {
  const [user] = useAuthState(auth);
  const [notes, setNotes] = useState([]);
  const [deletedNotes, setDeletedNotes] = useState([]);
  const [noteContent, setNoteContent] = useState('');
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertAction, setAlertAction] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editedNoteId, setEditedNoteId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);  // Controle do drawer
  const [spellCheckEnabled, setSpellCheckEnabled] = useState(false);  // Estado do spell check
  const [sortOrder, setSortOrder] = useState('normal');  // Estado para ordenação

  useEffect(() => {
    if (user) {
      const fetchNotes = async () => {
        try {
          const notesRef = collection(db, 'notes');
          const q = query(notesRef, where('creatorId', '==', user.uid));
          const querySnapshot = await getDocs(q);
          const fetchedNotes = [];
          const fetchedDeletedNotes = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.isDeleted) {
              fetchedDeletedNotes.push({ id: doc.id, ...data });
            } else {
              fetchedNotes.push({ id: doc.id, ...data });
            }
          });
          setNotes(fetchedNotes);
          setDeletedNotes(fetchedDeletedNotes);
        } catch (error) {
          console.error('Error fetching notes:', error);
        }
        setLoading(false);
      };

      fetchNotes();
    }
  }, [user]);

  const handleCreateNote = async () => {
    if (!noteContent) {
      alert('Note content is required.');
      return;
    }
  
    try {
      const newNoteRef = await addDoc(collection(db, 'notes'), {
        noteContent,
        createdAt: new Date(),
        updatedAt: new Date(),
        creatorId: user.uid,
        isDeleted: false,
      });
  
      setNotes([...notes, { id: newNoteRef.id, noteContent, createdAt: new Date(), updatedAt: new Date(), creatorId: user.uid, isDeleted: false }]);
      setNoteContent('');  // Limpar o campo após criação
    } catch (error) {
      console.error('Error creating note:', error);
    }
  };
  

  const handleUpdateNote = async (noteId, updatedContent) => {
    if (!noteId || !updatedContent) {
      alert('Note ID or content is missing.');
      return;
    }

    try {
      const noteRef = doc(db, 'notes', noteId);
      await updateDoc(noteRef, {
        noteContent: updatedContent,
        updatedAt: new Date(),
      });

      const updatedNotes = notes.map(note =>
        note.id === noteId ? { ...note, noteContent: updatedContent, updatedAt: new Date() } : note
      );
      setNotes(updatedNotes);
      setEditedNoteId(null);
      setSnackbarMessage('Nota atualizada com sucesso!');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const handleMenuClick = (event, noteId) => {
    setAnchorEl(event.currentTarget);
    setSelectedNoteId(noteId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedNoteId(null);
  };

  const handleDeleteNote = async () => {
    try {
      const noteRef = doc(db, 'notes', selectedNoteId);
      await updateDoc(noteRef, { isDeleted: true });
      const updatedNotes = notes.filter(note => note.id !== selectedNoteId);
      setNotes(updatedNotes);
      setDeletedNotes([...deletedNotes, { id: selectedNoteId, ...notes.find(note => note.id === selectedNoteId) }]);
      setOpenAlert(false);
    } catch (error) {
      console.error('Error deleting note:', error);
    }
    handleMenuClose();
  };

  const handlePublishNote = async () => {
    try {
      const noteRef = doc(db, 'notes', selectedNoteId);  // Referência à nota original
      const noteSnap = await getDoc(noteRef);  // Obter dados da nota
      if (noteSnap.exists()) {
        const noteData = noteSnap.data();
        if (noteData.createdAt && noteData.creatorId) {
          // Corrigir o caminho para ter um número par de segmentos (ex: 'public/{selectedNoteId}')
          const publicNoteRef = doc(db, 'public_notes', selectedNoteId);  // Caminho corrigido para 2 segmentos
          await setDoc(publicNoteRef, {
            ...noteData,
            publicatedAt: new Date(),  // Adicionar o campo de data de publicação
          });
          console.log('Note published successfully');
        } else {
          console.error('Missing required fields for publishing note.');
        }
      }
      setOpenAlert(false);  // Fechar o alerta
    } catch (error) {
      console.error('Error publishing note:', error);  // Log do erro
    }
    handleMenuClose();  // Fechar o menu
  };

  const handleSortOrderToggle = () => setSortOrder(sortOrder => sortOrder === 'normal' ? 'reverse' : 'normal');

  const handleAlertOpen = (action) => {
    setAlertAction(action);
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleContentEditableChange = (noteId, newContent) => {
    setEditedNoteId(noteId);
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const handleSpellCheckToggle = () => {
    setSpellCheckEnabled(!spellCheckEnabled);
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const sortedNotes = sortOrder === 'reverse' ? [...notes].reverse() : notes;

  return (
    <Container maxWidth="false">

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
        <Typography variant="h2">My Notes</Typography>
        <IconButton onClick={() => toggleDrawer(true)}>
          <TuneIcon />
        </IconButton>
      </Box>

      {/* Drawer de Configurações */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => toggleDrawer(false)}>
        <Box sx={{ width: 250, padding: 2 }}>
          <Typography variant="h6">Config</Typography>
          <FormControlLabel
            control={<Switch checked={spellCheckEnabled} onChange={handleSpellCheckToggle} />}
            label="Ativar Spell Check"
          />
          <FormControlLabel
            control={<Switch checked={sortOrder === 'reverse'} onChange={handleSortOrderToggle} />}
            label="Ordenação Invertida"
          />
        </Box>
      </Drawer>

      <Box sx={{ marginTop: 2, marginBottom: 1 }}>
        <Typography variant="h6">Create New Note</Typography>
      </Box>

      <NoteCard
        note={{ noteContent: "" }}
        handleMenuClick={() => { }}  // Placeholder para não gerar erros
        handleUpdateNote={() => { }}  // Placeholder para não gerar erros
        handleContentEditableChange={(id, newContent) => setNoteContent(newContent)}
        spellCheckEnabled={spellCheckEnabled}
        actionButtons={true}
        handleCreateNote={handleCreateNote}
      >
      </NoteCard>

      {/* Exibição das Notas */}
      <Masonry key={notes.length} columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={1} sx={{ marginTop: 4 }}>
        {notes.map(note => (
          <NoteCard
            key={note.id}
            note={note}
            handleMenuClick={handleMenuClick}
            handleUpdateNote={handleUpdateNote}
            handleContentEditableChange={handleContentEditableChange}
            spellCheckEnabled={spellCheckEnabled}
            contextMenuActions={true}
          />
        ))}
      </Masonry>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-hidden': false,  // Certifique-se de que isso esteja desabilitado
        }}
      >
        <MenuItem onClick={handlePublishNote}>Publish</MenuItem>
        <MenuItem onClick={() => handleAlertOpen('delete')}>Delete</MenuItem>
      </Menu>

      {/* Alerta para confirmação de ações */}
      <Dialog open={openAlert} onClose={handleAlertClose}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {alertAction} this note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose}>Cancel</Button>
          <Button onClick={alertAction === 'delete' ? handleDeleteNote : handlePublishNote} color="primary">
            {alertAction}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para Feedback */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default NotesPage;
