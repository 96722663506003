import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Menu, MenuItem, Alert, Snackbar, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { db } from '../../Config/firebaseConfig';
import { useAuth } from '../../Config/AuthContext';
import { collection, onSnapshot, query, where, updateDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';
import DateDisplay from '../DateDisplay/DateDisplay'; // Importando o novo componente

const TaskList = ({ onTaskAdded, onEditTask }) => {
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null); // For displaying detailed info
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const loadTasks = () => {
    if (!currentUser || !currentUser.uid) return;
    try {
      const adeskUserID = currentUser.uid.slice(0, 16);
      const tasksRef = collection(db, `tasks/${adeskUserID}/task`);
      const q = query(tasksRef, where('userId', '==', currentUser.uid));
  
      onSnapshot(q, (snapshot) => {
        const tasksData = snapshot.docs.map(doc => {
          const data = { id: doc.id, ...doc.data() };
  
          // Set status to 'Pending' if not present
          if (!data.status) {
            data.status = 'Pending';
          }
          return data;
        });
  
        // Sort tasks by updatedAt or createdAt
        const sortedTasks = tasksData.sort((a, b) => {
          const updatedA = a.updatedAt || a.createdAt;
          const updatedB = b.updatedAt || b.createdAt;
          return updatedB - updatedA; // Sort from newest to oldest
        });
  
        setTasks(sortedTasks);
        setCompletedTasks(sortedTasks.filter(task => task.status === 'Completed'));
      });
    } catch (error) {
      console.error('Error loading tasks:', error);
    }
  };
  
  

  useEffect(() => {
    if (currentUser) {
      loadTasks();
    }
  }, [currentUser]);

  const toggleTaskCompletion = async (taskId, currentStatus) => {
    try {
      const adeskUserID = currentUser.uid.slice(0, 16);
      const taskRef = doc(db, `tasks/${adeskUserID}/task`, taskId);
      const newStatus = currentStatus === 'Completed' ? 'Pending' : 'Completed';
      await updateDoc(taskRef, { status: newStatus });
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const fetchTaskById = async (taskId) => {
    try {
      const adeskUserID = currentUser.uid.slice(0, 16);
      const taskRef = doc(db, `tasks/${adeskUserID}/task`, taskId);
      const taskDoc = await getDoc(taskRef);
      
      // Verifica se o documento existe
      if (!taskDoc.exists()) {
        console.error('No such document!');
        return null; // Retorna null se o documento não existir
      }
  
      const data = { id: taskDoc.id, ...taskDoc.data() };

      // Converte `createdAt` e `updatedAt` para Date, se forem Timestamps
      if (data.createdAt) {
        data.createdAt = data.createdAt.toDate ? data.createdAt.toDate() : new Date(data.createdAt);
      }
      if (data.updatedAt) {
        data.updatedAt = data.updatedAt.toDate ? data.updatedAt.toDate() : new Date(data.updatedAt);
      }
  
      return data;
    } catch (error) {
      console.error('Error fetching task:', error);
    }
  };

  const handleTaskClick = async (taskId) => {
    try {
      const taskDetails = await fetchTaskById(taskId);
      setSelectedTask(taskDetails);
    } catch (error) {
      console.error('Error fetching task details:', error);
    }
  };

  const handleContextMenu = (event, taskId) => {
    event.preventDefault();
    setSelectedTaskId(taskId);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteTask = async () => {
    try {
      const adeskUserID = currentUser.uid.slice(0, 16);
      const taskRef = doc(db, `tasks/${adeskUserID}/task`, selectedTaskId);
      await deleteDoc(taskRef);
      setSnackbarMessage('Task deleted successfully!');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error deleting task:', error);
      setSnackbarMessage('Error deleting task. Please try again.');
      setOpenSnackbar(true);
    } finally {
      handleCloseMenu();
    }
  };

  const handleEditTask = async () => {
    try {
      const taskToEdit = await fetchTaskById(selectedTaskId);
      onEditTask(taskToEdit);
      handleCloseMenu();
    } catch (error) {
      console.error('Error opening task for editing:', error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Task List Section */}
      <Box sx={{ flex: 1, mt: 3 }}>
        {tasks.map((task) => (
          <Box key={task.id} sx={{ display: 'flex', mb: 1 }}>
            <Typography
              variant="body1"
              style={{ textDecoration: task.status === 'Completed' ? 'line-through' : 'none', flexGrow: 1, textAlign: 'left', paddingTop: 10, paddingBottom: 10 }}
              onClick={() => handleTaskClick(task.id)} // Handle task click
              onContextMenu={(event) => handleContextMenu(event, task.id)}
            >
              {task.task}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row' }}>
              <IconButton onClick={() => toggleTaskCompletion(task.id, task.status)}>
                {task.status === 'Completed' ? <CheckBoxIcon sx={{ color: 'green' }} /> : <CheckBoxOutlineBlankIcon />}
              </IconButton>
              <IconButton onClick={(event) => handleContextMenu(event, task.id)}>
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      <Divider orientation="vertical" flexItem />

      {/* Detailed Task Info Section */}
      <Box sx={{ flex: 1, mt: 3, pl: 2 }}>
  {selectedTask ? (
    <>
      <Typography variant="h6">Task Details</Typography>
      <Typography variant="body1"><strong>Task:</strong> {selectedTask.task}</Typography>
      <Typography variant="body1"><strong>Status:</strong> {selectedTask.status}</Typography>
      <Typography variant="body1">
        <strong>Created:</strong> <DateDisplay timestamp={selectedTask.createdAt} />
      </Typography>
      <Typography variant="body1">
        <strong>Updated:</strong> <DateDisplay timestamp={selectedTask.updatedAt} />
      </Typography>
    </>
  ) : (
    <Typography variant="body1">Select a task to see the details</Typography>
  )}
</Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleEditTask}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteTask}>Delete</MenuItem>
      </Menu>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TaskList;
