import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PhotoIcon from '@mui/icons-material/Photo';
import NoteIcon from '@mui/icons-material/Note';
import Home from './../Pages/Home/Home';
import Profile from './../Pages/Profile/Profile';
import Notes from './../Pages/Notes/PageNotes';
import ToDo from './../Pages/ToDo/ToDoPage';
import Store from '../Components/Store/Store';
import StoreManager from '../Components/Store/StoreManagerPage';
import ImageMetadataDropPage from '../Components/ImageMetadataDrop/ImageMetadataDrop';
import { Navigate } from 'react-router-dom';
import DocumentPage from '../Pages/Document/DocumentPage';
import ArticleIcon from '@mui/icons-material/Article';


const routes = [
  {
    path: '/',
    component: Home,
    navLabel: 'Desks',
    pageTitle: 'aDesk (alpha)',
    icon: <AssessmentIcon />,
    showInNav: true,
  },
  {
    path: '/profile',
    component: Profile,
    navLabel: 'Profile',
    pageTitle: 'Profile',
    icon: <AccountCircleIcon />,
    showInNav: false,
  },
  {
    path: '/to/:nickname',
    component: Profile,
    navLabel: 'Profile',
    pageTitle: 'Profile',
    showInNav: false, // Hidden in navigation
  },
  {
    path: '/notes',
    component: Notes,
    navLabel: 'Notes',
    pageTitle: 'Notes',
    icon: <NoteIcon />,
    showInNav: false, // Shown in navigation
  },
  {
    path: '/documents',
    component: DocumentPage,
    navLabel: 'Documents',
    pageTitle: 'Documents',
    icon: <ArticleIcon />,
    showInNav: true, // Shown in navigation
  },
  {
    path: '/tasks',
    component: ToDo,
    navLabel: 'Tasks',
    pageTitle: 'Tasks',
    icon: <AssignmentTurnedInIcon />,
    showInNav: true, // Shown in navigation
  },
  {
    path: '/tools/image-metadata',
    component: ImageMetadataDropPage,
    navLabel: 'Metadata',
    pageTitle: 'Image Metadata',
    icon: <PhotoIcon />,
    showInNav: true, // Shown in navigation
  },
  {
    path: '/store',
    component: StoreManager,
    navLabel: 'My Store',
    pageTitle: 'My Store (alpha)',
    icon: <ShoppingCartIcon />,
    showInNav: true,
  },
  {
    path: '/store/:adeskUserID/ads/:productId',
    component: Store, // Now you should pass just the function or class
    navLabel: 'Offer Details',
    pageTitle: 'Offer Details',
    icon: <ShoppingCartIcon />,
    showInNav: false,
  }
];

export default routes;

