import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import documentConfig from '../../Config/documentTypes.json';

const DocumentEditor = ({ document, onUpdateDocument }) => {
    const [content, setContent] = useState(document.content);
    const [documentType, setDocumentType] = useState(document.documentType);
    const [loading, setLoading] = useState(false);
    const [fieldValues, setFieldValues] = useState({});
    const [tempFieldValues, setTempFieldValues] = useState({});

    const documentTypes = documentConfig.documentTypes;

    useEffect(() => {
        setContent(document.content);
        setDocumentType(document.documentType);

        const currentDocType = documentTypes.find((type) => type.docType === document.documentType);
        if (currentDocType) {
            const initialFieldValues = {};
            Object.keys(currentDocType.fields).forEach((field) => {
                initialFieldValues[field] = document[field] || '';
            });
            setFieldValues(initialFieldValues);
            setTempFieldValues(initialFieldValues);
        } else {
            setFieldValues({});
            setTempFieldValues({});
        }
    }, [document, documentTypes]);

    const handleSave = () => {
        setLoading(true);
        const updatedDoc = {
            ...document,
            content,
            documentType,
            ...fieldValues,
            updatedAt: [...document.updatedAt, new Date()],
            contentLastVersion: new Date(),
        };
        onUpdateDocument(updatedDoc);
        setLoading(false);
    };

    const handleFieldChange = (field, value) => {
        setFieldValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));

        // Atualiza o valor temporário para o campo específico, mas não para o 'content'
        setTempFieldValues((prevTempValues) => ({
            ...prevTempValues,
            [field]: value,
        }));
    };

    const handleContentChange = (value) => {
        setContent(value);

        // Armazena o valor de content de forma persistente entre os tipos
        setTempFieldValues((prevTempValues) => ({
            ...prevTempValues,
            content: value,
        }));
    };

    const handleDocumentTypeChange = (e) => {
        const newType = e.target.value;

        // Salva os valores atuais dos campos específicos temporariamente antes de mudar o tipo
        setTempFieldValues((prevTempFieldValues) => ({
            ...prevTempFieldValues,
            [documentType]: fieldValues,
        }));

        setDocumentType(newType);

        // Atualiza os campos conforme o novo tipo de documento, restaurando se houver valores temporários
        const newDocType = documentTypes.find((type) => type.docType === newType);
        if (newDocType) {
            const restoredValues = tempFieldValues[newType] || {};
            const initialFieldValues = {};
            Object.keys(newDocType.fields).forEach((field) => {
                initialFieldValues[field] = restoredValues[field] || '';
            });
            setFieldValues(initialFieldValues);
        } else {
            setFieldValues({});
        }

        // Restaura o valor de content, se já existir no estado temporário
        if (tempFieldValues.content) {
            setContent(tempFieldValues.content);
        }
    };

    const renderField = (fieldKey, fieldConfig) => {
        const value = fieldValues[fieldKey] || '';

        switch (fieldConfig.input) {
            case 'textarea':
                return (
                    <TextField
                        key={fieldKey}
                        label={fieldConfig.description}
                        multiline
                        minRows={4}
                        fullWidth
                        value={value}
                        onChange={(e) => handleFieldChange(fieldKey, e.target.value)}
                        variant="outlined"
                    />
                );
            case 'select':
                return (
                    <FormControl key={fieldKey} fullWidth={fieldConfig.fieldWidth ? false : true} sx={{ maxWidth: fieldConfig.fieldWidth ? fieldConfig.fieldWidth : 140 }} size="small">
                        <InputLabel>{fieldConfig.description}</InputLabel>
                        <Select
                            value={value}
                            onChange={(e) => handleFieldChange(fieldKey, e.target.value)}
                            label={fieldConfig.description}
                            sx={{ textAlign: 'left' }}
                        >
                            {fieldConfig.options.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'datePicker':
                return (
                    <DatePicker
                        key={fieldKey}
                        label={fieldConfig.description}
                        value={value instanceof Date ? value : null}
                        onChange={(date) => handleFieldChange(fieldKey, date)}
                        sx={{ maxWidth: fieldConfig.fieldWidth ? fieldConfig.fieldWidth : 140 }} 
                        renderInput={(params) => <TextField {...params} size="small" fullWidth={fieldConfig.fieldWidth ? false : true} sx={{ maxWidth: fieldConfig.fieldWidth ? fieldConfig.fieldWidth : 140 }} />}
                    />
                );
            default:
                return (
                    <TextField
                        key={fieldKey}
                        label={fieldConfig.description}
                        value={value}
                        onChange={(e) => handleFieldChange(fieldKey, e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                );
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ flex: 1, padding: 2 }}>
                <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControl sx={{ width: '12em', textAlign: 'left' }} size="small" variant="outlined">
                        <InputLabel>Document Type</InputLabel>
                        <Select
                            value={documentType}
                            label="Document Type"
                            onChange={handleDocumentTypeChange}
                        >
                            {documentTypes.map((type, index) => (
                                <MenuItem key={index} value={type.docType}>
                                    {type.docType}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Campo de conteúdo compartilhado entre os tipos de documentos */}
                    <TextField
                        label="Content"
                        multiline
                        minRows={4}
                        fullWidth
                        value={content}
                        onChange={(e) => handleContentChange(e.target.value)}
                        variant="outlined"
                    />

                    {/* Renderizar campos dinâmicos */}
                    {documentTypes.find((type) => type.docType === documentType) &&
                        Object.keys(documentTypes.find((type) => type.docType === documentType).fields).map((fieldKey) =>
                            renderField(fieldKey, documentTypes.find((type) => type.docType === documentType).fields[fieldKey])
                        )}
                    {/* Botão de Salvar */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size='large'
                            onClick={handleSave}
                            disabled={loading}
                            sx={{ marginTop: 2 }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Save ' + documentType}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default DocumentEditor;
