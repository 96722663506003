import React, { useState } from 'react';
import { Card, CardContent, CardActions, IconButton, Button, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhotoUpload from './../../Components/PhotoUpload/PhotoUpload'; // Certifique-se de que o caminho esteja correto

const NoteCard = ({
  note,
  actionButtons,
  handleCreateNote,
  contextMenuActions,
  handleMenuClick,
  handleUpdateNote,
  handleContentEditableChange,
  spellCheckEnabled,
  isEditing = false,
}) => {
  const [showPhotoUpload, setShowPhotoUpload] = useState(false);

  // Função que será chamada quando a imagem for carregada com sucesso
  const handlePhotoUpload = (imageURL) => {
    // Atualiza a nota com a URL da imagem
    handleUpdateNote(note.id, note.noteContent, imageURL);
  };

  const togglePhotoUpload = () => {
    setShowPhotoUpload((prev) => !prev);
  };

  const isContentEmpty = !note.noteContent || note.noteContent.trim() === '';

  return (
    <Card sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', position: 'relative' }} key={note.id}>
      {note.imageURL && !isEditing && (
        <div style={{
          backgroundImage: `url(${note.imageURL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '200px',
          width: '100%',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }} />
      )}

      <CardContent sx={{ flex: 1, p: 1.5, position: 'relative' }}>
        <div
          contentEditable={!isEditing}
          suppressContentEditableWarning
          onBlur={(e) => !isEditing && handleUpdateNote(note.id, e.target.innerText, note.imageURL)}
          onInput={(e) => !isEditing && handleContentEditableChange(note.id, e.target.innerText)}
          spellCheck={spellCheckEnabled}
          style={{
            minHeight: '100px',
            whiteSpace: 'pre-wrap',
            position: 'relative',
            zIndex: 1,
            padding: '0',
            overflow: 'hidden',
          }}
        >
          {isEditing ? 'Escreva aqui...' : note.noteContent}
        </div>

        {isContentEmpty && !isEditing && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: 'none',
              opacity: 0.6,
              zIndex: 0,
              userSelect: 'none',
            }}
          >
          </Typography>
        )}

        {showPhotoUpload && (
          <PhotoUpload onPhotoUpload={handlePhotoUpload} />
        )}
      </CardContent>

      {contextMenuActions && !isEditing && (
        <CardActions sx={{ p: 0.5, display: 'flex', alignItems: 'flex-start' }}>
          <IconButton size="small" onClick={(event) => handleMenuClick(event, note.id)}>
            <MoreVertIcon />
          </IconButton>
        </CardActions>
      )}

      {actionButtons && (
        <CardActions>
          <Button onClick={handleCreateNote} variant="contained">{isEditing ? 'Update' : 'Save'}</Button>
          {/* Botão para exibir/ocultar o upload de imagem */}
          <Button onClick={togglePhotoUpload} variant="outlined">
            {showPhotoUpload ? 'Cancel Image' : 'Add Image'}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default NoteCard;
