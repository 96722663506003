// src/Components/DateDisplay.js
import React from 'react';

const DateDisplay = ({ timestamp }) => {
  if (!timestamp) return 'N/A';

  // Verifica se é um objeto Timestamp do Firebase
  const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);
  
  return <span>{date.toLocaleString()}</span>;
};

export default DateDisplay;
