import React from 'react';
import { List, ListItem, ListItemText, Divider, colors } from '@mui/material';

const DocumentList = ({ documents, onSelect, selectedDoc }) => {
    return (
        <List sx={{ width: '30em', borderRight: '1px solid #222', maxHeight: '100vh', overflow: 'auto', padding: 0 }}>
            {documents.map((doc) => (
                <React.Fragment key={doc.id}>
                    <ListItem
                        button
                        onClick={() => onSelect(doc)}
                        sx={{ cursor: 'pointer', borderRadius: 0 }} // Estiliza o item selecionado
                        selected={selectedDoc && selectedDoc.id === doc.id} // Marca o item como selecionado
                        className={selectedDoc && selectedDoc.id === doc.id ? 'Mui-selected' : ''} // Adiciona a classe 'selected' se o item for selecionado
                    >
                        <ListItemText
                            primary={typeof doc.content === 'string' ? doc.content.split('\n')[0] : 'No Content'}
                            secondary={doc.documentType || 'Unknown Type'}
                        />
                    </ListItem>
                    <Divider />
                </React.Fragment>
            ))}
        </List>
    );
};

export default DocumentList;
