import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import routes from '../../Config/Routes';
import { auth, provider, signInWithPopup, signOut } from '../../Config/firebaseConfig';
import { Box, AppBar, Toolbar, IconButton, Avatar, Button, Menu, MenuItem, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const Navbar = ({ changeMode, mode }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [pagetitle, setPagetitle] = useState('');
  const location = useLocation();  // Hook to get the current location
  const siteName = 'aDesk';

  useEffect(() => {
    // Atualiza o título da aba do navegador
    const currentRoute = routes.find(route => route.path === location.pathname);
    setPagetitle(currentRoute ? currentRoute.pageTitle : '');

    if (currentRoute) {
      document.title = `${currentRoute.navLabel} - ${siteName}`;
    } else {
      document.title = siteName;
    }
  }, [location]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "rgba(0,0,0,0)", boxShadow: "none", color: `${mode === 'light' ? "black" : "white"}` }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          {pagetitle && (
            <Box sx={{ ml: 2, color: 'inherit', fontFamily: 'Jost', letterSpacing: -1, fontWeight: '800', userSelect: 'none', cursor: 'default', textTransform: 'uppercase', fontSize: 24 }}>
              {pagetitle}
            </Box>
          )}
        </Box>

        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} sx={{ backgroundColor: "rgba(0,0,0, 0)" }}>
          <Box sx={{ width: 250, padding: 2, fontSize: 24, fontWeight: '800', fontFamily: 'Jost', letterSpacing: -2, textTransform: 'uppercase', cursor: 'default' }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            {siteName}
          </Box>
          <Divider />
          <List>
            {routes
              .filter(route => route.showInNav)
              .map((route, index) => (
                <ListItem user={user} key={index} component={Link} to={route.path} onClick={toggleDrawer(false)}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.navLabel} />
                </ListItem>
              ))}
          </List>
        </Drawer>

        <IconButton color="inherit" onClick={changeMode}>
          {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
        </IconButton>

        <Box sx={{ flexGrow: 0, ml: 2 }}>
          {user ? (
            <>
              <IconButton onClick={handleMenu} color="inherit" sx={{ p: 0 }}>
                <Avatar alt={user.displayName} src={user.photoURL} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem component={Link} to="/profile">Profile</MenuItem>
                <Divider sx={{ minWidth: '10rem', maxWidth: '90vw' }} />
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <Button color="inherit" onClick={handleLogin}>
              Login
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
