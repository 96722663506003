import React from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { Storage, Speed, Cloud, Info } from '@mui/icons-material';

const PromotionalContent = ({ handleLogin }) => {
  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: 4 }}>
        {/* Hero Section */}
        <Box sx={{ textAlign: 'center', padding: 4, backgroundColor: 'background.paper', borderRadius: 2 }}>
          <Typography variant="h1" component="h1" gutterBottom>
            Acelere as tarefas do cotidiano
          </Typography>
          <Typography variant="h5" component="p" gutterBottom>
            Organize suas informações de forma fácil e dinâmica.
          </Typography>
          <Button disabled variant="contained" color="primary" size="large" onClick={handleLogin}>
            Comece Agora
          </Button>
        </Box>

        {/* Features Section */}
        <Typography variant="h4" component="h2" sx={{ marginTop: 4, marginBottom: 2 }}>
          Nossos Recursos
        </Typography>
        <Grid container spacing={4}>
          {/* Feature 1 */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ textAlign: 'center', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
              <Storage sx={{ fontSize: 50, color: 'primary.main' }} />
              <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
                Armazenamento Local
              </Typography>
              <Typography variant="body1">
                Mantenha suas informações no seu dispositivo para acesso rápido e offline.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 2 */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ textAlign: 'center', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
              <Cloud sx={{ fontSize: 50, color: 'primary.main' }} />
              <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
                Armazenamento na Nuvem
              </Typography>
              <Typography variant="body1">
                Opte por usar o Google para armazenar suas informações e acessá-las de qualquer lugar.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 3 */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ textAlign: 'center', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
              <Speed sx={{ fontSize: 50, color: 'primary.main' }} />
              <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
                Acesso Rápido
              </Typography>
              <Typography variant="body1">
                Navegue e encontre suas informações em segundos com nossa interface intuitiva.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 4 */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ textAlign: 'center', padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
              <Info sx={{ fontSize: 50, color: 'primary.main' }} />
              <Typography variant="h6" component="h3" sx={{ marginTop: 1 }}>
                Informações Organizadas
              </Typography>
              <Typography variant="body1">
                Organize suas informações de maneira lógica e fácil de entender.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Footer */}
        <Box sx={{ textAlign: 'center', marginTop: 4, padding: 4 }}>
          <Typography variant="body2">
            © 2024 aDesk.link - Todos os direitos reservados.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default PromotionalContent;
