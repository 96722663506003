import React from 'react';
import { Button, Typography, Box } from '@mui/material';


const Dashboard = () => {
  return (
    <>
      <Box sx={{ textAlign: 'center', padding: 4, backgroundColor: 'background.paper', borderRadius: 2, marginTop: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Versão alpha
          </Typography>
          <Typography variant="h5" component="p" gutterBottom>
            Esta página ainda está em desenvolvimento. As ferramentas oferecidas estão em versão de teste e podem conter bugs. Utilize a navegação superior para navegar entre as telas.
          </Typography>
        </Box>
    </>
  );
};

export default Dashboard;
