import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Container,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
    Drawer,
    IconButton,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DocumentList from '../../Components/Document/DocumentList';
import DocumentEditor from '../../Components/Document/DocumentEditor';
import DocumentSettingsPage from '../../Components/Document/DocumentSettingsPage';
import { useAuth } from '../../Config/AuthContext';
import { db } from '../../Config/firebaseConfig';
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc } from 'firebase/firestore';
import DateDisplay from '../../Components/DateDisplay/DateDisplay';

const DocumentPage = () => {
    const { currentUser } = useAuth();
    const [documents, setDocuments] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSettingsDrawer, setOpenSettingsDrawer] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteInput, setDeleteInput] = useState('');

    useEffect(() => {
        if (currentUser) {
            loadDocuments();
        }
    }, [currentUser]);

    const DetailsInfo = (info) => {
        return (
            <Box sx={{ mt: 1, mb: 1 }}>
                <Typography variant="body2" color="text.secondary">
                    {info.title}
                </Typography>
                <Typography variant="body2">
                    {info.content}
                </Typography>
            </Box>
        );
    };

    const loadDocuments = async () => {
        try {
            const docRef = collection(db, `documents/${currentUser.uid.slice(0, 16)}/docs`);
            const querySnapshot = await getDocs(docRef);
            const loadedDocs = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setDocuments(loadedDocs);
            setLoading(false);
        } catch (error) {
            console.error('Error loading documents:', error);
            setSnackbarMessage('Error loading documents.');
            setOpenSnackbar(true);
        }
    };

    const handleCreateDocument = async () => {
        try {
            const newDoc = {
                creatorId: currentUser.uid,
                creatorName: currentUser.displayName || 'Anonymous',
                createdAt: new Date(),
                updatedAt: [],
                documentType: 'note',
                content: 'New Document',
                contentLastVersion: new Date(),
            };
            const docRef = collection(db, `documents/${currentUser.uid.slice(0, 16)}/docs`);
            const docSnap = await addDoc(docRef, newDoc);
            
            const createdDoc = { id: docSnap.id, ...newDoc };
            setDocuments([...documents, createdDoc]); // Adiciona o novo documento à lista
            setSelectedDoc(createdDoc); // Seleciona o novo documento
            setSnackbarMessage('Document created successfully.');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error creating document:', error);
            setSnackbarMessage('Error creating document.');
            setOpenSnackbar(true);
        }
    };
    

    const handleUpdateDocument = async (updatedDoc) => {
        try {
            const docRef = doc(db, `documents/${currentUser.uid.slice(0, 16)}/docs`, updatedDoc.id);
            await updateDoc(docRef, updatedDoc);
            const updatedDocs = documents.map((doc) => (doc.id === updatedDoc.id ? updatedDoc : doc));
            setDocuments(updatedDocs);
            setSnackbarMessage('Document updated successfully.');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error updating document:', error);
            setSnackbarMessage('Error updating document.');
            setOpenSnackbar(true);
        }
    };

    const handleDeleteDocument = async () => {
        try {
            const docRef = doc(db, `documents/${currentUser.uid.slice(0, 16)}/docs`, selectedDoc.id);
            await deleteDoc(docRef);
            setDocuments(documents.filter((doc) => doc.id !== selectedDoc.id));
            setSelectedDoc(null);
            setSnackbarMessage('Document deleted successfully.');
            setOpenSnackbar(true);
            setOpenDeleteDialog(false);
            setDeleteInput('');
        } catch (error) {
            console.error('Error deleting document:', error);
            setSnackbarMessage('Error deleting document.');
            setOpenSnackbar(true);
        }
    };

    const handleSnackbarClose = () => setOpenSnackbar(false);

    const handleToggleDrawer = (open) => () => {
        setOpenSettingsDrawer(open);
    };

    const renderDocumentInfo = () => {
        if (!selectedDoc) return null;

        const charCount = selectedDoc.content ? selectedDoc.content.length : 0;

        return (
            <Box sx={{ width: 200, padding: 2, borderLeft: '1px solid #222', textAlign: 'left', overflow: 'auto', wordBreak: 'break-word' }}>
                <Typography variant="caption" color='text.secondary'>Document Info</Typography>
                <Divider style={{ marginTop: 4, marginBottom: 8 }} />
                <DetailsInfo title="user" content={selectedDoc.creatorId.slice(0, 16)} />
                {selectedDoc.creatorName && <DetailsInfo title="name" content={selectedDoc.creatorName} />}
                <DetailsInfo title="created at" content={<DateDisplay timestamp={selectedDoc.createdAt} />} />
                {selectedDoc.updatedAt.length > 0 && <DetailsInfo title="updated at" content={<DateDisplay timestamp={selectedDoc.updatedAt[selectedDoc.updatedAt.length - 1]} />} />}
                <DetailsInfo title="character count" content={charCount} />
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setOpenDeleteDialog(true)}
                    sx={{ mt: 2 }}
                >
                    Delete Document
                </Button>
            </Box>
        );
    };

    return (
        <Container maxWidth="false">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 4 }}>
                <Typography variant="h4">My documents</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <IconButton color="primary" onClick={handleToggleDrawer(true)}>
                        <SettingsIcon />
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5, mr: 2 }} orientation="vertical" /> */}
                    <Button variant="contained" color="primary" onClick={handleCreateDocument}>
                        New Document
                    </Button>
                </Box>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', marginTop: 4 }}>
                <DocumentList documents={documents} onSelect={setSelectedDoc} selectedDoc={selectedDoc} />

                    {selectedDoc && (
                        <>
                            <DocumentEditor
                                document={selectedDoc}
                                onUpdateDocument={handleUpdateDocument}
                            />
                            {renderDocumentInfo()}
                        </>
                    )}
                </Box>
            )}

            <Drawer anchor="right" open={openSettingsDrawer} onClose={handleToggleDrawer(false)}>
                <Box sx={{ width: 350, padding: 2 }}>
                    <DocumentSettingsPage />
                </Box>
            </Drawer>

            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        To confirm the deletion of this document, please type <strong>"delete"</strong> in the box below.
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={deleteInput}
                        onChange={(e) => setDeleteInput(e.target.value)}
                        placeholder="Type 'delete' to confirm"
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteDocument}
                        color="error"
                        disabled={deleteInput !== 'delete'}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default DocumentPage;
