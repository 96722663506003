import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../Config/firebaseConfig';  // Certifique-se de que o auth e firebase estão configurados

const PhotoUpload = ({ onPhotoUpload }) => {
  const [user] = useAuthState(auth);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const storage = getStorage();

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  // Handle photo capture using camera
  const handleCapture = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleUpload = async () => {
    if (!user || !imageFile) return;

    setIsUploading(true);

    try {
      const storageRef = ref(storage, `${user.uid}/notes/${imageFile.name}`);
      await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(storageRef);

      // Chama a função de callback passada como prop para associar a imagem à nota
      onPhotoUpload(downloadURL);

      setImageFile(null);
      setImagePreview('');
      setIsUploading(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setIsUploading(false);
    }
  };

  return (
    <div>
      <h4>Upload Photo</h4>
      <input
        accept="image/*"
        type="file"
        capture="camera" // Abre diretamente a câmera no mobile
        onChange={handleCapture}
      />
      <input
        accept="image/*"
        type="file"
        onChange={handleFileChange}
      />
      
      {imagePreview && (
        <div>
          <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />
          <button onClick={handleUpload} disabled={isUploading}>
            {isUploading ? 'Uploading...' : 'Upload Image'}
          </button>
        </div>
      )}
    </div>
  );
};

export default PhotoUpload;
