import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Container } from "@mui/material";

const ImageMetadataDrop = () => {
  const [metadata, setMetadata] = useState([]);
  const [error, setError] = useState(null);
  const [imageSrc, setImageSrc] = useState(null); // Armazena a URL da imagem carregada

  const handleDrop = (e) => {
    e.preventDefault();
    setError(null);

    const file = e.dataTransfer.files[0];
    console.log("Arquivo arrastado:", file);

    if (file && file.type === "image/png") {
      const reader = new FileReader();

      reader.onload = () => {
        console.log("Imagem carregada com sucesso.");

        const arrayBuffer = reader.result;
        const metadata = extractPNGMetadata(arrayBuffer);

        if (metadata) {
          setMetadata(metadata);
          console.log("Metadados PNG extraídos:", metadata);
        } else {
          setError("Nenhum metadado PNG relevante encontrado.");
        }
      };

      reader.onerror = () => {
        console.error("Erro ao ler a imagem");
        setError("Erro ao ler a imagem");
      };

      reader.readAsArrayBuffer(file);

      // Gerar URL temporária para exibir a imagem
      const imageUrl = URL.createObjectURL(file);
      setImageSrc(imageUrl); // Definir a URL para exibir a imagem
    } else {
      console.error("Tipo de arquivo inválido.");
      setError("Por favor, arraste um arquivo PNG válido.");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveImage = () => {
    setImageSrc(null); // Remove a imagem
    setMetadata([]); // Limpar os metadados
  };

  const extractPNGMetadata = (arrayBuffer) => {
    const dataView = new DataView(arrayBuffer);
    let offset = 8; // Pular assinatura PNG

    const metadata = [];

    while (offset < dataView.byteLength) {
      const chunkLength = dataView.getUint32(offset);
      const chunkType = String.fromCharCode(
        dataView.getUint8(offset + 4),
        dataView.getUint8(offset + 5),
        dataView.getUint8(offset + 6),
        dataView.getUint8(offset + 7)
      );

      // Se for um chunk de texto, tentamos extrair
      if (chunkType === 'tEXt') {
        const chunkData = new TextDecoder().decode(
          new Uint8Array(arrayBuffer, offset + 8, chunkLength)
        );
        const [key, value] = chunkData.split('\0'); // Chave e valor estão separados por null byte
        metadata.push({ key, value });
      }

      offset += 12 + chunkLength; // 12 = 4 (comprimento) + 4 (tipo) + 4 (CRC)
    }

    return metadata.length ? metadata : null;
  };

  const saveAsJSON = (key, value) => {
    let jsonValue;
    
    // Tentar parsear o valor se ele for uma string JSON válida
    try {
      jsonValue = JSON.parse(value); // Parseia o valor se ele for uma string JSON válida
    } catch (e) {
      jsonValue = value; // Se não for JSON, mantém o valor original
    }
  
    const json = JSON.stringify(jsonValue, null, 2); // Formatar como JSON
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    
    // Criar link temporário para baixar o arquivo
    const link = document.createElement("a");
    link.href = url;
    link.download = `${key}.json`;
    document.body.appendChild(link);
    link.click();
    
    // Limpar o URL temporário e remover o link
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const isValidJSON = (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <Container>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          border: "2px dashed #ccc",
          borderRadius: "10px",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          position: "relative",
          minHeight: "200px", // Definindo uma altura fixa para a área de drop
        }}
      >
        {imageSrc ? (
          <div style={{ position: "relative" }}>
            <img 
              src={imageSrc} 
              alt="Imagem PNG" 
              style={{ maxWidth: "100%", maxHeight: "100%" }} 
            />
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={handleRemoveImage}
              style={{ position: "absolute", top: 10, right: 10 }}
            >
              Remover Imagem
            </Button>
          </div>
        ) : (
          "Arraste e solte uma imagem PNG aqui"
        )}
      </div>

      {error && <p style={{ color: "red" }}>{error}</p>}

      {metadata.length > 0 && (
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Chave</strong></TableCell>
                <TableCell><strong>Conteúdo</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metadata.map((meta, index) => (
                <TableRow key={index}>
                  <TableCell>{meta.key}</TableCell>
                  <TableCell>
                    {meta.key === 'workflow' || meta.key === 'prompt' ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => saveAsJSON(meta.key, meta.value)}
                      >{console.log(meta.key)}
                        Salvar como JSON
                      </Button>
                    ) : (
                      <span>{meta.value}</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default ImageMetadataDrop;
